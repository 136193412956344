/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import TitleSecondary from 'components/elements/TitleSecondary'
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const CustomImage = styled(Image)`
  width: 500px;

  @media (max-width: 991px){
    width: 100%;
  }
`

const List = styled.ul`
  list-style: none;
  margin-left: -2.4rem;
`

const FirstColumn = styled.div`
  @media (max-width: 991px){
    order: 1;
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} type="article" />

      <section>
        <div className="container">
          <div className="row py-5 my-lg-5 ">
            
            <FirstColumn className="col-lg-6 pt-4 pb-4 pb-lg-0">
              <TitleDefault>{title}</TitleDefault>
              {acf.content.ingredients && (
                <>
                <TitleSecondary>Ingredienten</TitleSecondary>
                <List>
                  {acf.content.ingredients.map(({ ingredient }, index)=> (
                    <li>{ingredient}</li>
                  ))}
                </List>
                </>
              )}
              <ParseContent content={acf.content.description} className="mb-5" />
              <ButtonDefault to='/bestellen'>Bestellen</ButtonDefault>
            </FirstColumn>
            <div className="col-lg-6">
              <CustomImage src={acf.overview.image} className="mb-4"/>
            </div>
          </div>
        </div>
      </section>

     
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      acf {
        overview {
          image {
            localFile {
              publicURL
            }
          }
        }
        content {
          description
          ingredients {
            ingredient
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
